import { useStaticQuery, graphql } from 'gatsby'

export const useReleases = () => {
  const data = useStaticQuery(graphql`
    query {
      allReleaseNotesJson {
        nodes {
          details {
            header
            list
            paragraphs
            type
          }
          highlights
          releaseDate
          version
        }
      }
    }
  `)
  return data.allReleaseNotesJson.nodes
}
